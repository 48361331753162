import * as React from 'react'
import { SVGProps } from 'react'

export const Website = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={18}
    width={18}
    {...props}
  >
    <path
      fill="currentColor"
      d="M9 1.5c-2.446 0-4.615 1.183-5.985 3H3v.019a7.475 7.475 0 0 0-1.426 3.44c-.48 3.404 1.529 6.564 4.671 7.957a6.696 6.696 0 0 0 2.758.582.75.75 0 0 0 .747-.752V9.75A.75.75 0 0 0 9 9H7.5a.75.75 0 1 1 .01-1.5.743.743 0 0 0 .74-.75V6A.75.75 0 0 1 9 5.25h.75a1.5 1.5 0 0 0 1.5-1.5v-.308A6.006 6.006 0 0 1 15 9L15 9.026h.003a.75.75 0 1 0 1.495-.096C16.46 4.827 13.112 1.5 9 1.5ZM3.157 7.657 6.75 11.25V12a1.5 1.5 0 0 0 1.5 1.5v1.449C5.295 14.578 3 12.054 3 9c0-.462.058-.91.157-1.343Zm8.568.575a.537.537 0 0 0-.475.537v5.357c0 .329.395.498.635.27l1.07-1.018 1.16 2.675a.9.9 0 1 0 1.644-.728l-1.166-2.575h1.182c.338 0 .504-.41.261-.645l-3.87-3.725a.53.53 0 0 0-.441-.148Z"
    />
  </svg>
)
