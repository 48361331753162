import * as React from 'react'
import { SVGProps } from 'react'

export const EthicalBadgeMonoChrome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.364 5.276a.636.636 0 0 0-.637.636l.001 7.626a.314.314 0 0 1-.19.29L9 16.5l-5.537-2.672a.318.318 0 0 1-.19-.289V5.912a.636.636 0 0 0-.637-.636A.636.636 0 0 1 2 4.639v-.751C2 3.12 2.621 2.5 3.388 2.5h11.224C15.38 2.5 16 3.121 16 3.888v.752a.636.636 0 0 1-.636.636Z"
    />
    <path
      fill="currentColor"
      d="M9 7.4v9.1l-5.537-2.672a.318.318 0 0 1-.19-.289V5.912a.636.636 0 0 0-.637-.636A.636.636 0 0 1 2 4.639v-.751C2 3.12 2.621 2.5 3.388 2.5H9v4.9Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M6.438 7.445c.28-.273.66-.427 1.057-.427.397 0 .777.154 1.057.427l.439.428.438-.428a1.527 1.527 0 0 1 2.133-.018c.14.137.251.3.327.48a1.427 1.427 0 0 1-.346 1.602L8.991 12 6.438 9.509A1.441 1.441 0 0 1 6 8.477c0-.387.157-.758.438-1.032Z"
      clipRule="evenodd"
    />
  </svg>
)
