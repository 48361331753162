// import { GetServerFeatureToggle } from '@/components/FeatureFlags'
import { KeyOf } from '@/types/generics'
import Image from 'next/image'
import {
  FolderCopy,
  Bookmark,
  AutoStories,
  Storage,
  Dashboard,
  Key,
  Build,
  Help,
  CorporateFare,
  Person,
  Rocket,
  Chat,
  DataUsage,
  Settings,
  Code,
} from '@mui/icons-material'
import { DashboardLink } from '@/components/dashboard/sidebar/SidebarTemplate'
import TrainingAASIcon from '@public/assets/illustrations/logos/small/training_aas.svg'
import VlmAASIcon from '@public/assets/illustrations/logos/small/vlm_aas.svg'
import { EthicalBadgeMonoChrome } from '@/components/Icons/EthicalBadgeMonoChrome'
import { Slack } from '@/components/Icons/Slack'
import { Website } from '@/components/Icons/Website'
import { HonorFill } from '@/components/Icons/HonorFill'

// const [isEnabled] = GetServerFeatureToggle()

export const SUPPORT = {
  teaserEmail: 'hafnia@milestonesys.com',
  email: 'hafnia@milestonesys.com',
  apiKey: 'https://hafnia.readme.io/docs/create-an-api-key',
  upvoteFeatures: 'https://hafnia.readme.io/discuss',
  scriptSamples: 'https://hafnia.readme.io/docs/using-scripts',
  help: 'https://hafnia.readme.io',
  docs: 'https://hafnia.readme.io/docs',
  pricing: 'https://hafnia.readme.io/docs/pricing-plans',
  slack:
    'https://join.slack.com/t/hafniacommunity/shared_invite/zt-31c90ltjm-LLqeQUXQgj2fKmwBehrfCA',
}

export const SUPPORT_DOCS = {
  VLM: 'https://hafnia.readme.io/docs/about-vlm-api',
  VLM_QUICK_START:
    'https://dash.readme.com/project/mdi/v02/docs/vlm-quickstart',
  VLM_PROMPT_EXAMPLES: 'https://hafnia.readme.io/docs/prompt-examples',
} as const

export const DATASET_PAGE_SIZE = 10

export const DASHBOARD_HREFS = {
  BASE: '/dashboard',
  API_KEYS: '/dashboard/api-keys',
} as const

export const DASHBOARD_LINKS: DashboardLink[] = [
  {
    title: 'Dashboard',
    href: DASHBOARD_HREFS.BASE,
    Icon: <Dashboard className="h-5 w-5" />,
  },
  {
    title: 'API Keys',
    href: DASHBOARD_HREFS.API_KEYS,
    Icon: <Key className="h-5 w-5" sx={{ transform: 'rotate(90deg)' }} />,
  },
]

export const TRAINING_AAS_DASHBOARD_HREFS = {
  BASE: '/training-aas',
  EXPERIMENTS: '/training-aas/experiments',
  UPLOADED_SCRIPTS: '/training-aas/experiments/uploaded-scripts',
  MY_COLLECTION: '/training-aas/my-collection',
  DATASETS: '/training-aas/datasets',
} as const

export const SERVICES_HREFS = {
  TRAINING_AAS: '/training-aas',
  VLM_AAS: '/vlm-aas',
}

export const SERVICES_LINKS: DashboardLink[] = [
  {
    title: 'Training-aaS',
    href: TRAINING_AAS_DASHBOARD_HREFS.EXPERIMENTS,
    Icon: <Image src={TrainingAASIcon} alt="Training AAS Icon" />,
  },
  {
    title: 'VLM-aaS',
    href: SERVICES_HREFS.VLM_AAS,
    Icon: <Image src={VlmAASIcon} alt="VLM AAS Icon" />,
  },
]

export const SETTINGS_HREFS = {
  ORGANIZATION: '/dashboard/organization',
  ACCOUNT: '/dashboard/account-settings',
  ETHICAL_OATH: '/dashboard/ethical-developer-badge',
}
export const SETTINGS_LINKS: DashboardLink[] = [
  {
    href: SETTINGS_HREFS.ORGANIZATION,
    title: 'Organization',
    Icon: <CorporateFare className="h-5 w-5" />,
  },
  {
    href: SETTINGS_HREFS.ACCOUNT,
    title: 'Account',
    Icon: <Person className="h-5 w-5" />,
  },
  {
    href: SETTINGS_HREFS.ETHICAL_OATH,
    title: 'Ethical Oath',
    Icon: <EthicalBadgeMonoChrome className="h-5 w-5" />,
  },
]

export const TRAINING_AAS_DASHBOARD_LINKS: DashboardLink[] = [
  {
    title: 'My Experiments',
    href: TRAINING_AAS_DASHBOARD_HREFS.EXPERIMENTS,
    Icon: <FolderCopy className="h-5 w-5" />,
    groupId: 'Experiments',
  },
  {
    title: 'My Scripts',
    href: TRAINING_AAS_DASHBOARD_HREFS.UPLOADED_SCRIPTS,
    Icon: <Code className="h-5 w-5" />,
    groupId: 'Experiments',
  },
  {
    title: 'My Collection',
    href: TRAINING_AAS_DASHBOARD_HREFS.MY_COLLECTION,
    Icon: <Bookmark className="h-5 w-5" />,
  },
  {
    title: 'Datasets',
    href: TRAINING_AAS_DASHBOARD_HREFS.DATASETS,
    Icon: <Storage className="h-5 w-5" />,
  },
  {
    title: 'Documentation',
    href: SUPPORT.docs,
    Icon: <AutoStories className="h-5 w-5" />,
    newTab: true,
  },
]

export const VLM_AAS_DASHBOARD_HREFS = {
  BASE: '/vlm-aas',
  OVERVIEW: '/vlm-aas',
  USAGE: '/vlm-aas/usage',
  DOCUMENTATION: SUPPORT_DOCS.VLM,
  QUICK_START: SUPPORT_DOCS.VLM_QUICK_START,
  PROMPT_EXAMPLES: SUPPORT_DOCS.VLM_PROMPT_EXAMPLES,
} as const

export const VLM_AAS_DASHBOARD_LINKS: DashboardLink[] = [
  {
    title: 'Overview',
    href: VLM_AAS_DASHBOARD_HREFS.OVERVIEW,
    Icon: <Dashboard className="h-5 w-5" />,
  },
  // {
  //   title: 'Usage',
  //   href: VLM_AAS_DASHBOARD_HREFS.USAGE,
  //   Icon: <DataUsage />,
  // },
  {
    title: 'Quick Start',
    href: VLM_AAS_DASHBOARD_HREFS.QUICK_START,
    Icon: <Rocket className="h-5 w-5" />,
    newTab: true,
    disabled: true,
  },
  {
    title: 'Documentation',
    href: VLM_AAS_DASHBOARD_HREFS.DOCUMENTATION,
    Icon: <AutoStories className="h-5 w-5" />,
    newTab: true,
    disabled: true,
  },
  {
    title: 'Prompt examples',
    href: VLM_AAS_DASHBOARD_HREFS.PROMPT_EXAMPLES,
    Icon: <Chat className="h-5 w-5" />,
    newTab: true,
    disabled: true,
  },
]

export const AVATAR_NAVBAR_LINKS: DashboardLink[] = [
  {
    newTab: false,
    title: 'Organization',
    href: '/dashboard/organization',
    Icon: <CorporateFare />,
  },
  {
    newTab: false,
    title: 'Ethical Oath',
    href: '/dashboard/ethical-developer-badge',
    Icon: <HonorFill />,
  },
  {
    newTab: false,
    title: 'Account Settings',
    href: '/dashboard/account-settings',
    Icon: <Settings />,
  },
]

export const DASHBOARD_LINKS_HELP_WIDGET: DashboardLink[] = [
  {
    newTab: true,
    title: 'Join Slack',
    href: SUPPORT.slack,
    Icon: <Slack className="h-5 w-5" />,
  },
  {
    title: 'Documentation',
    href: SUPPORT.docs,
    Icon: <AutoStories className="h-5 w-5" />,
    newTab: true,
  },
  {
    title: 'Help & Support',
    href: SUPPORT.help,
    Icon: <Help className="h-5 w-5" />,
    newTab: true,
  },
  {
    title: 'Website',
    href: '/',
    Icon: <Website className="h-5 w-5" />,
    newTab: true,
  },
]

export const DATA_LIBRARY_DETAILS_IDS = {
  overview: 'overview',
  classes: 'classes',
  fairness: 'fairness',
  annotation: 'annotation',
  dataset_lineage: 'dataset_lineage',
  usage_reference: 'usage_reference',
} as const

export type DataLibraryDetailsId = KeyOf<typeof DATA_LIBRARY_DETAILS_IDS>
export const DATA_LIBRARY_DETAILS_LINKS = [
  {
    section: DATA_LIBRARY_DETAILS_IDS.overview,
    title: 'Overview',
  },
  {
    section: DATA_LIBRARY_DETAILS_IDS.classes,
    title: 'Classes',
  },
  {
    section: DATA_LIBRARY_DETAILS_IDS.fairness,
    title: 'Fairness',
  },

  {
    section: DATA_LIBRARY_DETAILS_IDS.annotation,
    title: 'Annotation',
  },
  // {
  //   section: DATA_LIBRARY_DETAILS_IDS.datasetLineage,
  //   title: 'Dataset Lineage',
  // },
  {
    section: DATA_LIBRARY_DETAILS_IDS.usage_reference,
    title: 'Usage Reference',
  },
]

export const API_PATHS = {
  personalDetails: '/api/accountSettings/personalDetails',
  changePassword: '/api/accountSettings/changePassword',
  register: '/api/auth/register',
  confirmUser: '/api/auth/confirmUser',
  resendCode: '/api/auth/resendCode',
}

export const PASSWORD_REGEX =
  /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/

export const TOOLTIP = {
  disabledDatasetSection: 'No data available!',
  disabledTableButton: 'Not available yet!',
  failedUpload: 'Check your email for more info!',
  traceability:
    'The Traceability Score represents how much is known about a dataset, and how well it is documented. It covers three areas: dataset source(s), metadata, and annotations. The score is calculated by Project Hafnia as a metric for transparency.',
  bookmarkSave: 'Save to collection',
  bookmarkRemove: 'Remove from collection',
  trainingMetrics: 'View training metrics',
  trainingDownload: 'Download Model',
  trainingRemove: 'Remove training',
  verifiedLabelTitle: 'What does it mean that a dataset is verified?',
  verifiedLabel:
    'A verified dataset refers to a collection meticulously curated by the Project Hafnia team, adhering to the utmost ethical and legal standards.',
}

export const LOCAL_STORAGE_SIDEBAR_STATE = 'IS_WELCOME_DIALOG_STATE_CLOSED'
export const LOCAL_STORAGE_VLM_QUICK_START_GUIDE_STATE =
  'IS_VLM_QUICK_START_GUIDE_DIALOG_STATE_CLOSED'

export const PHASES = {
  EXPORT: 'phase-export',
  PRODUCTION_BUILD: 'phase-production-build',
  PRODUCTION_SERVER: 'phase-production-server',
  DEVELOPMENT_SERVER: 'phase-development-server',
  TEST: 'phase-test',
}

export const internalNav = {
  auth: {
    signin: '/auth/signin',
    register: '/auth/register',
  },
  aboutUs: '/about-us',
  contactUs: '/contact-us',
  privacyPolicy: '/privacy-policy-user',
  termsOfService: '/terms-of-service',
  cookiePolicy: '/cookie-policy',
  ethics: '/ethics',
  features: '/features',
  dataLibrary: '/data-library',
  popularDatasets: '/popular-datasets',
  apiKeys: '/dashboard/api-keys',
}

export const DASHBOARDS_ROUTES = [
  DASHBOARD_HREFS.BASE,
  TRAINING_AAS_DASHBOARD_HREFS.BASE,
  VLM_AAS_DASHBOARD_HREFS.BASE,
]
