import * as React from 'react'
import { SVGProps } from 'react'

export const HonorFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.75 3v11.04a.375.375 0 0 1-.223.344L9 17.273l-6.527-2.888a.375.375 0 0 1-.223-.344V3H.75V1.5h16.5V3h-1.5ZM6 9v1.5h6V9H6Zm0-3v1.5h6V6H6Z"
    />
  </svg>
)
