import * as React from 'react'
import { SVGProps } from 'react'

export const Slack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    width={18}
    height={18}
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        d="M11.077 7.385H8.615V1.23a1.23 1.23 0 1 1 2.462 0v6.155Zm2.461 0H12.31v-1.23a1.231 1.231 0 1 1 1.23 1.23m1.23 1.23H8.614v2.462h6.155a1.231 1.231 0 0 0 0-2.461Zm-6.154 4.923V12.31h1.23a1.23 1.23 0 1 1-1.23 1.23M1.23 4.923h6.155v2.462H1.23a1.231 1.231 0 0 1 0-2.462Zm6.154-2.461v1.23h-1.23a1.23 1.23 0 1 1 1.23-1.23ZM1.23 9.845c0-.678.55-1.23 1.23-1.23h1.232v1.23a1.231 1.231 0 0 1-2.462 0Zm3.692 4.924V8.614h2.462v6.155a1.23 1.23 0 1 1-2.462 0Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)
